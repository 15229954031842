import * as React from 'react'
import Seo from '../components/seo'
import { Hero } from '../layouts/index'
import About from '../layouts/about'
import Layout from '../layouts/layout'
import Events from '../layouts/events'
import Gallery from '../layouts/gallery'
import Partners from '../layouts/partners'

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <About />
      <Events />
      <Gallery numberImages={8} />
      <Partners />
    </Layout>
  )
}

export const Head = () => <Seo title="Accueil" />
export default IndexPage
