import { Link } from 'gatsby';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { btnOutline, section, bgColor } from '../styles/styles';

const imagesPath = '../assets'

const Hero = () => {
    return (
        <section className={`${section} bg-[#0c1934]`}>
            <div className="wrapper-sm sm:px-6 lg:px-8">
                <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
                    <div>
                        <div className='relative'>
                            <h1 className="title relative z-10 text-primary sm:text-4xl lg:text-5xl">KNR Events
                                <span className="text-3xl font-light mt-4 text-slate-100 block sm:ml-0">
                                    Votre accompagnant
                                </span>
                                <span className="text-3xl font-bold text-slate-100 sm:block sm:ml-0">
                                    Dans vos projets sportifs
                                </span>
                            </h1>
                            <span className="absolute inline-block h-[15px] z-1 inset-x-0 -left-2 -bottom-0.5 rounded-sm bg-gradient-to-r from-amber-600 to-primary -rotate-1 max-w-[70%] opacity-90"></span>
                        </div>

                        <p>
                            KNR EVENTS est une société spécialisée dans la conception, l’organisation et l’accompagnement dans vos projets sportifs. Fondée en 2019 par des passionnés et pratiquants de sports d’endurance : VTT, CYCLISME, TRAIL ET TRIATHLON.
                        </p>
                        <div className="mt-10 sm:flex sm:items-center sm:space-x-8">
                            <Link to='/knr-events'
                                className={btnOutline}
                            >
                                Explorer
                            </Link> 

                        </div>
                    </div>

                    <div className='sm:p-2 py-4 shadow- bg-gray-900 px-0'>
                        <StaticImage
                            className="w-full h-auto"
                            src={`${imagesPath}/about.png`}
                            alt="Knr Events"
                        />

                        {/* <img className="w-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/hero/2/hero-img.png" alt="" /> */}
                    </div>
                </div>
            </div>
        </section >
    )
}
export default Hero;