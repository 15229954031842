import React from 'react'
import { Link } from "gatsby";
import { btnPrimary, btnOutline, section, bgColor } from '../styles/styles';
import Photos from '../components/photos';

const Gallery = ({ numberImages }) => {

    return (
        <section className={`${section}  bg-[#0c1934]`}>
            <div className="wrapper-lg sm:px-6 lg:px-8">
                <div className=" mx-auto text-center">
                    <h2 className="title text-primary sm:text-4xl lg:text-5xl">
                        Galerie
                    </h2>
                    {/* Photos */}
                    {/* <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 py-4 mt-14'> */}
                    <div className='gap-8   py-4 sm:mt-14'>
                        <Photos numberImages={8} />                      
                    </div>
                </div>
                <div className="flex justify-center px-4 space-x-5 items-center  sm:px-0 mt-2 sm:mt-14">
                    <Link
                        to="/inscription"
                        className={btnPrimary}
                        role="button"
                    >
                        Inscription
                    </Link>

                    <Link
                        to="/gallery"
                        className={btnOutline}
                    >
                        Galerie
                    </Link>
                </div>
            </div>

        </section >

    )
}
export default Gallery;

