import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import { section, btnPrimary, btnOutline } from '../styles/styles'
import dossier from '../../static/dossier-sponsoring-tafraout-camp.pdf'
const imagesPath = '../assets'

const Partners = () => {
    return (
        <section className={`${section} bg-white`}>
            <div className="flex gap-y-8 gap-x-4 flex-wrap flex-col xl:flex-row  px-4 wrapper-lg sm:px-6 lg:px-8 items-center">
                <div className="flex-1 text-center">
                    <h2 className="mt-5 text-4xl font-black leading-tight text-[#0c1934] sm:leading-tight sm:text-5xl uppercase">
                        Un Grand
                        <span className="relative block text-secondary">Merci</span>
                    </h2>
                    <p className='text-2xl px-5 dark'>
                        à nos prestataires, partenaires
                        <span className='block'>
                            supporters, bénévoles
                        </span>
                    </p>


                    <div className="px-0  space-y-4 sm:space-y-0 sm:items-center sm:justify-center  sm:px-0 space-x-5 sm:flex mt-6">
                        <Link
                            to="/inscription"
                            className={btnPrimary}
                            role="button"
                        >
                            Inscription
                        </Link>

                        <a
                            href={dossier}
                            className={btnOutline}
                        >
                            Dossier de sponsoring 
                        </a>

                        {/* 
                        <a href={dossier} download>Download the directly imported file</a>{` `} */}
                        {/*                         <a href={`download.pdf`} download>Download the file that is already in your static folder</a> */}
                    </div>
                </div>

                <div className='flex-1 flex ' >
                    <div className="w-full flex flex-col sm:gap-0 sm:px-4  sm:justify-around items-center ">

                        {/*  <div className="grid items-center w-full grid-cols-2 sm:grid-cols-3 mx-auto lg:grid-cols-4 gap-x-10 gap-y-20">
                            <div>
                                <img className="object-contain w-full h-6 mx-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/logos/3/logo-1.png" alt="" />
                            </div>

                            <div>
                                <img className="object-contain w-full h-8 mx-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/logos/3/logo-2.png" alt="" />
                            </div>

                            <div>
                                <img className="object-contain w-full h-8 mx-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/logos/3/logo-3.png" alt="" />
                            </div>

                            <div>
                                <img className="object-contain w-full mx-auto h-7" src="https://cdn.rareblocks.xyz/collection/celebration/images/logos/3/logo-4.png" alt="" />
                            </div>

                            <div className="">
                                <img className="object-contain w-full h-8 mx-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/logos/3/logo-5.png" alt="" />
                            </div>

                            <div className="">
                                <img className="object-contain w-full h-8 mx-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/logos/3/logo-6.png" alt="" />
                            </div>

                            <div className="">
                                <img className="object-contain w-full h-8 mx-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/logos/3/logo-7.png" alt="" />
                            </div>

                            <div className="">
                                <img className="object-contain w-full h-8 mx-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/logos/3/logo-8.png" alt="" />
                            </div>

                            <div className="">
                                <img className="object-contain w-full h-8 mx-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/logos/3/logo-9.png" alt="" />
                            </div>

                            <div className="">
                                <img className="object-contain w-full mx-auto h-7" src="https://cdn.rareblocks.xyz/collection/celebration/images/logos/3/logo-10.png" alt="" />
                            </div>

                            <div className="">
                                <img className="object-contain w-full h-8 mx-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/logos/3/logo-11.png" alt="" />
                            </div>

                            <div className="">
                                <img className="object-contain w-full h-8 mx-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/logos/3/logo-12.png" alt="" />
                            </div>


                        </div> */}
                        <h2 className="title sm:text-5xl">Ils nous ont fait confiance</h2>
                        <div className='flex gap-4 sm:gap-10 px-4 mt-6 sm:px-10 sm:justify-between items-center'>
                            <div className='px-4'>
                                <StaticImage
                                    className="h-auto max-w-full max-h-full relative rounded-xl"
                                    src={`${imagesPath}/partners/naftam.png`}
                                    alt="camp tafraout"
                                />
                            </div>

                            <div className='px-4'>
                                <StaticImage
                                    className="h-auto max-w-full max-h-full relative rounded-xl"
                                    src={`${imagesPath}/partners/logo-localub.png`}
                                    alt="camp tafraout"
                                />
                            </div>
                        </div>

                        <div className='sm:px-4 sm:py-4'>
                            <StaticImage
                                className="h-auto max-w-full max-h-full relative rounded-xl"
                                src={`${imagesPath}/partners/partners.png`}
                                alt="camp tafraout"
                            />
                        </div>
                    </div>
                </div>

            </div>
        </section >

    )
}

export default Partners