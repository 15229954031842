import React, { useEffect } from 'react'
import { Link } from 'gatsby';
import { btnPrimary, btnOutline, section } from '../styles/styles';
import Count from '../components/count';

const Hero = () => {


  return (
    <section className={`${section} border-t-0`}>
      <div className="px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <p className="px-6 text-3xl text-[#0c1934] font-text font-light tracking-wide uppercase">Nouvel an, nouveau evenement! </p>
          <h1 className="mt-2 mb-3 text-4xl sm:leading-tight event sm:text-5xl lg:text-6xl uppercase">
            Tafraout Camp
            <span className="relative block text-primary">Trail & Bike.</span>
          </h1>

         {/*  <div className="">
            <Count />
          </div> */}

          <div className="px-4 space-y-3 sm:space-y-0 sm:items-center sm:justify-center  sm:space-x-5 sm:px-0 flex flex-col sm:flex-row mt-9">
            <Link
              to="/inscription"
              className={btnPrimary}
              role="button"
            >
              Inscription
            </Link>

            <Link
              to="/camp-tafraout"
              className={btnOutline}
            >
              Découvrir
            </Link>
          </div>
        </div>
        </div>

    </section>

  )
}
export default Hero;