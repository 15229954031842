import React from 'react'
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import { section } from '../styles/styles';

const imagesPath = '../assets'
const Events = () => {

    return (
        <section className={section}>
            <div className="wrapper-sm sm:px-6 lg:px-8">
                <div className=" mx-auto text-center">
                    <h2 className="title text-secondary sm:text-5xl">
                        Nos événements
                        <span className="relative mt-3 text-3xl sm:text-4xl block text-[#0c1934]">Conception & organisation</span>
                    </h2>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-16 py-8 mt-14 '> 

                        {/* item */}
                        <div className="relative flex  flex-col justify-self-center p-2 sm:p-6 w-full  transition-all duration-200 transform  border  group border-neutral-200 bg-slate-50 rounded-xl hover:shadow-lg">

                            <iframe className='w-full aspect-video rounded-xl' src="https://www.youtube.com/embed/NrE0Apjxgdo"
                                title="Trail N'Timlilt" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                            <div className='flex flex-row justify-center items-center overflow-hidden'>
                                <StaticImage
                                    className="hidden sm:block w-[100px] sm:w-[150px] h-auto"
                                    src={`${imagesPath}/events/trail-ntimlilt.png`}
                                    alt="Trail N'Timlilt"
                                />

                                <div className="flex-1 flex flex-row justify-around sm:justify-between items-center p-2 sm:p-6  w-full">
                                    <p className="text-lg sm:text-2xl font-bold text-[#0c1934] ">Trail N'Timlilt
                                        <span className=" block text-sm font-normal leading-6 text-gray-500 line-clamp-3">16 octobre 2022</span>
                                    </p>

                                    <Link
                                        to="/knr-events#trail-ntimlilt"
                                        className='text-primary underline text-base sm:text-lg block'
                                    >
                                        Explorer
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {/* item */}
                        <div className="relative flex flex-col justify-self-center p-2 sm:p-6 w-full  transition-all duration-200 transform  border  group border-neutral-200 bg-slate-50 rounded-xl hover:shadow-lg">

                            <iframe className='w-full aspect-video rounded-xl' src="https://www.youtube.com/embed/H2SMpG4TLys"
                                title="Trail N'Timlilt" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                            <div className='flex flex-row overflow-hidden'>
                                <StaticImage
                                    className="hidden sm:block w-[100px] sm:w-[150px] h-auto"
                                    src={`${imagesPath}/events/raid-de-lamitie-vtt.png`}
                                    alt="Raid de l'amitie VTT"
                                />


                                <div className="flex-1 flex flex-row justify-around sm:justify-between items-center p-2 sm:p-6  w-full">
                                    <p className="text-lg sm:text-2xl font-bold text-[#0c1934] ">Raid de l'amitie VTT
                                        <span className=" block text-sm font-normal leading-6 text-gray-500 line-clamp-3">2022</span>
                                    </p>
                                    <Link
                                        to="/knr-events#raid-amitie"
                                        className='text-primary underline text-base sm:text-lg block'
                                    >
                                        Explorer
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section >

    )
}
export default Events;

